#iplTrivizha * {
  font-family: var(--font-family-rubik);
}

.hoster_slider_wrapper {
  padding: 16px;
}

.streaming_voice_sec svg {
  cursor: pointer;
}

.ipl_trivizha_header h1 {
  font-size: 14px;
  line-height: 21px;
}

.ipl_slider {
  padding: 10px 0px 0px;
}

.inner_ipl_slider {
  height: 160px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  text-align: center;
}

.ipl_slider_img {
  padding: 3px;
  width: 72px;
  height: 72px;
  position: relative;
}

.ipl_slider_img img {
  width: 100%;
  height: 100%;
}

.ipl_host_graphic_eq {
  position: absolute;
  left: 56px;
  top: 56px;
}

.ipl_trivizha_img_border_host,
.ipl_trivizha_img_border {
  border-radius: 50%;
}

.ipl_trivizha_img_border_host {
  border: 1px solid var(--border-blue);
}

.ipl_trivizha_img_border {
  border: 1px solid var(--border-white);
}

.inner_ipl_slider_highlights_host {
  transform: scale(1.25);
}

.inner_ipl_slider_NotHighlights_host {
  transform: scale(1);
}

.ipl_stories_guest_details {
  display: flex;
  flex-direction: column;
}

.ipl_stories_guest_details h6 {
  font-size: 14px;
  font-weight: var(--font-weight-500);
  line-height: 16.59px;
}

.ipl_stories_guest_details small {
  font-size: 12px;
  font-weight: var(--font-weight-300);
  line-height: 11.85px;
}

.ipl_stories_speaking {
  color: var(--text-blue);
}

.ipl_stories_notSpeaking {
  color: var(--text-white);
}

/* IPL TRIVIZHA MAIN */

.ipl_trivizha_main {
  margin-top: 16px;
}

.ipl_trivizha_main_row {
  margin-bottom: 110px;
}

.ipl_trivizha_main_header h4 {
  background: var(--bg-light-white-2-gradiant);
  text-align: center;
  padding: 8px;
  letter-spacing: 2px;
  line-height: 23.7px;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.ipl_trivizha_main_header span {
  font-family: var(--font-family-tourney);
  color: var(--text-warning-2);
  font-style: italic;
  line-height: 22px;
}

.ipl_trivizha_main .card {
  padding: 0;
  margin: 0 0 16px;
}

.ipl_trivizha_main .card .card-header,
.ipl_trivizha_main .card .card-body,
.ipl_trivizha_main .card .card-footer {
  padding: 13px 16px;
}

.ipl_trivizha_main .card .card-header {
  border-bottom: 1px solid var(--border-light-gray-3-gradiant);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ipl_trivizha_main .card .card-header p,
.ipl_trivizha_main .card .card-footer p {
  color: var(--text-gray-9);
  font-weight: var(--font-weight-500);
  font-size: 12px;
  line-height: 14.22px;
}

.card_main-heading h2 {
  font-size: 20px;
  font-weight: var(--font-weight-500);
  line-height: 18.96px;
  color: var(--text-dark-2);
  margin-bottom: 16px;
}

.ipl_trivizha_main .card .card-footer {
  border-top: 1px solid var(--border-light-gray-3-gradiant);
}

.ipl_trivizha_options ul li {
  border-radius: 30px;
  border: 1px solid var(--border-gray-2);
  font-weight: var(--font-weight-400);
  color: var(--text-gray-8);
  text-align: center;
  line-height: 16.59px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

/* Bottom Menu Styles */

.ipl_trivizha_msg_input_container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0%;
  right: 0;
}

.ipl_trivizha_msg_input_container input {
  width: 100%;
  padding: 10px 80px 10px 10px;
  border: 1px solid var(--border-gray-2);
  background-color: transparent;
  color: var(--text-light-gray-7);
  border-radius: 50px;
}

.ipl_trivizha_inner_container,
.ipl_trivizha_get,
.ipl_trivizha_get div,
.ipl_trivizha_speak {
  display: flex;
  align-items: center;
}

.ipl_trivizha_inner_container {
  justify-content: space-between;
  background-color: var(--bg-dark);
  gap: 23px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 65px;
  padding: 15px;
}

.ipl_trivizha_input {
  position: relative;
  width: 100%;
}

.ipl_trivizha_input .btn {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.ipl_trivizha_get {
  flex-direction: column;
  gap: 5px;
}

.ipl_trivizha_get div {
  gap: 2px;
  color: var(--text-white);
  font-weight: var(--font-weight-400);
  line-height: 11.85px;
}

.ipl_trivizha_speak {
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  border: 1px solid var(--text-light-gray-7);
  justify-content: center;
}

/* Comments Styles */
.ipl_trivizha_comments {
  position: fixed;
  bottom: 60px;
  display: flex;
  background: var(--bg-transparent-gray);
  overflow: hidden;
  backdrop-filter: blur(10px);
  align-items: end;
  transition: 0.5s ease-in-out;
  width: 100%;
  right: 0%;
  left: 0%;
}

.minimize_chat {
  bottom: -60px;
}

.minimize_chat+span {
  bottom: 75px;
}

.stream_parent {
  /* max-width: 860px;
  overflow: hidden; */
}

.streaming_responsive {
  /* width: 100%;
  position: fixed;
  top: 100px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  max-height: calc(100% - 120px);  
  pointer-events:inherit;   */

}

.streaming_view {
  width: 145px;
}

.streaming_video {
  border: 2px solid #3e3a3a;
  width: fit-content;
  overflow: hidden;
  border-radius: 5px;
  margin: 10px;
}

.streaming_video .video_container {
  width: 120px;
  height: 140px;
  position: relative;
  background-color: #000;
}

.streaming_video .video_container .toggle_button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(225, 225, 225, 0.5);
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 20px;
  padding: 4px;
  border: 1px solid #fff;
}


.streaming_video .host_states_container {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  backdrop-filter: blur(10px);
}

.streaming_video .host_states_container h5 {
  font-size: 10px;
  font-weight: 500;

}

.streaming_video .action_buttons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.streaming_video .action_buttons>div {
  background: rgba(225, 225, 225, 0.5);
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

video {
  object-fit: cover !important;
}

@media (min-width: 768px) {
  .ipl_trivizha_comments {
    width: 100%;
    max-width: 720px;
    margin: 0 auto;
    left: 0%;
    right: 0%;
  }

  /* .chatbox_wrapper span {
    right: 25%;
  } */
}

.ipl_trivizha_comments ul {
  width: 100%;
  height: 100%;
  max-height: 120px;
  min-height: 120px;
  overflow-y: auto;
  /* scrollbar-width: none; */
  display: grid;
  align-items: end;
  position: relative;
}

.ipl_trivizha_comments ul::-webkit-scrollbar {
  width: 5px;
  background-color: #e0e3eb;
  border-radius: 10px;
}

.ipl_trivizha_comments ul::-webkit-scrollbar-track {
  -webkit-box-shadow: #e0e3eb;
  border-radius: 10px;
}

.ipl_trivizha_comments ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
}

.stream_parent .chatbox_wrapper .chat_minimize_icon {
  position: absolute;
  align-items: center;
  display: flex;
  text-align: center;
  right: 23%;
  width: max-content;
  bottom: 175px;
  height: max-content;
  background-color: var(--text-warning-2);
  box-shadow: 4px 7px 21px var(--bg-transparent-gray);
  padding: 6px;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.stream_parent .chatbox_wrapper .chat_minimize {
  bottom: 65px;
}

.ipl_trivizha_comments ul li {
  border-bottom: 1px dashed var(--text-light-gray-6);
  padding: 2px 0;
  margin: 0 5px;
}

.ipl_trivizha_comment_list {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
  gap: 10px;
}

.ipl_trivizha_comment_user_img img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.ipl_trivizha_comment_user_comment {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ipl_trivizha_comment_user_comment h4 {
  color: var(--text-white);
  font-weight: var(--font-weight-500);
  font-size: 12px;
  line-height: 14.22px;
}

.ipl_trivizha_comment_user_comment p {
  font-size: 11px;
  line-height: 11.85px;
  color: var(--text-light-white);
}

/* Video Call Styles */

.pbee_ipl_trivizha_videoCall {
  /* position: fixed; */
  /* top: 80px;
  right: 200px;
  z-index: 99999; */
  background-color: var(--bg-dark-2);
  border-radius: 8px;
  padding: 5px;
}

.pbee_ipl_trivizha_close svg path {
  fill: #22a5f5 !important;
}

.pbee_ipl_trivizha_close svg {
  position: absolute;
  top: -20px;
  right: 0;
  cursor: pointer;
}

.pbee_ipl_trivizha_close svg:nth-child(2) {
  position: absolute;
  top: -20px;
  left: 0;
  cursor: pointer;
}

.pbee_ipl_trivizha_videoCall_inner {
  padding: 4px 4px 8px 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.pbee_ipl_trivizha_hosters_img_wrapper {
  display: grid;
  align-items: center;
  grid-column: auto;
  border: 1px solid #ffffff;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.streaming_container {
  position: sticky;
  z-index: 3;
  top: 80px;
}

@media (max-width:650px) {
  .streaming_container {
    top: 60px;
  }
}

/* width */
.remote-video-container::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}

/* Track */
.remote-video-container::-webkit-scrollbar-track {
  background: #363636;
}

/* Handle */
.remote-video-container::-webkit-scrollbar-thumb {
  background: #4e4e4e;
}

/* Handle on hover */
.remote-video-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.remote-video-container {
  box-sizing: border-box;
  display: inline-flex;
  align-items: stretch;
  max-width: 100%;
  overflow-x: auto;
  padding: 10px 0px;
  gap: 5px;
  flex-wrap: nowrap;
  /* background: var(--common-bg); */
}

.remote-video-container>div {
  min-width: 100px;

}

.remote-video-container .video_container_small {

  width: 100px;
  height: 100px;
  border: 1px solid #444444;
  border-radius: 5px;
  overflow: hidden;

}

.pbee_ipl_trivizha_hosters_img_wrapper img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.pbee_ipl_trivizha_video {
  position: relative;
  border: 1px solid var(--border-white);
  border-radius: 8px;
  overflow: hidden;
}

.pbee_ipl_trivizha_host_name {
  position: absolute;
  bottom: -3px;
  width: 100%;
  padding: 2px 12px;
  display: flex;
  justify-content: space-between;
  background: var(--bg-transparent-gray-2);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  align-items: center;
  left: 0;
  border-radius: 8px;
}

.pbee_ipl_trivizha_host_name h3,
.pbee_ipl_trivizha_host_name small,
.pbee_ipl_trivizha_video_screen_name h3 .pbee_ipl_trivizha_video_screen_name small {
  font-weight: var(--font-weight-400);
}

.pbee_ipl_trivizha_host_name h3 {
  line-height: 14.22px;
}

.pbee_ipl_trivizha_host_name small {
  color: var(--text-light-gray-6);
}

.pbee_ipl_trivizha_video img {
  object-fit: cover;
  object-position: center;
}

/* Video Screen Page Styles */

.pbee_ipl_trivizha_video_screen_page {
  padding: 34px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.pbee_ipl_trivizha_video_screen {
  border: 1px solid var(--border-white);
  width: 100%;
  max-width: 328px;
  height: 173px;
  margin: auto;
  border-radius: 8px;
}

.pbee_ipl_trivizha_video_screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.pbee_ipl_trivizha_video_screen {
  position: relative;
  overflow: hidden;
}

.pbee_ipl_trivizha_video_screen_name {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  background: var(--bg-transparent-gray-2);
  backdrop-filter: blur(10px);
  align-items: center;
}

.pbee_ipl_trivizha_video_screen_name h3 {
  font-size: 18px;
  line-height: 21.33px;
}

.pbee_ipl_trivizha_video_screen_name small {
  font-size: 14px;
  line-height: 16.59px;
  color: var(--text-light-gray-6);
}

.ipl_trivizha_main_header .user_count {
  font-size: 20px;
  line-height: 22px;
}

.user_count {
  margin-left: auto;
  cursor: pointer;
}

.joinUserList {
  width: 100%;
  padding: 20px;
}

.joinUserList div {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.joinUserList div button {
  border: none;
  background-color: transparent;
}

@media (max-width: 768px) {
  .stream_parent .chatbox_wrapper .chat_minimize_icon {
    right: 0%;
  }
}

@media (min-width: 768px) {
  .ipl_trivizha_main_header h4 {
    font-size: 20px;
    padding: 8px;
    line-height: 35.55px;
  }



  .ipl_trivizha_main_header span {
    line-height: 22px;
  }

  .card_main-heading h2 {
    font-size: 24px;
    line-height: 28.44px;
  }

  .ipl_trivizha_main .card .card-header img {
    width: 30px;
    height: 30px;
  }

  .ipl_trivizha_main .card .card-header p,
  .ipl_trivizha_main .card .card-footer p {
    font-size: 16px;
    line-height: 18.96px;
  }

  .ipl_trivizha_main .card .card-body {
    padding: 20px 16px;
  }

  .ipl_trivizha_options ul li {
    width: 100%;
    max-width: 450px;
    margin: auto;
    line-height: 21.33px;
    padding: 12px;
    margin-top: 16px;
    font-size: 24px;
  }

  .ipl_trivizha_msg_input_container {
    /* width: 54%;
    left: 23%; */
    /* max-width: 720px; */
    max-width: 35%;
    margin: 0 auto;
    z-index: 99999;
  }

  .ipl_trivizha_msg_input_container input {
    font-size: 14px;
    line-height: 16.59px;
  }

  .ipl_trivizha_get div {
    font-size: 14px;
    line-height: 16.59px;
  }

  .ipl_trivizha_speak {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .ipl_trivizha_input .btn {
    right: 10px;
  }
}

@media (min-width: 1200px) {

  .ipl_slider .slick-prev,
  .ipl_slider .slick-next {
    width: auto;
    height: auto;
    top: 200px;
  }

  .ipl_trivizha_main {
    /* margin-top: 80px; */
  }

  .ipl_slider .slick-prev {
    left: auto;
    right: 60px;
  }

  .ipl_slider .slick-next {
    right: 20px;
  }

  .ipl_slider .slick-next::before {
    content: url("../images/arrow-right.png");
  }

  .ipl_slider .slick-prev::before {
    content: url("../images/arrow-left.png");
  }

  .ipl_stories_guest_details h6 {
    font-size: 14px;
    line-height: 28.44px;
  }

  .ipl_stories_guest_details small {
    font-size: 8px;
    line-height: 21.33px;
  }

  .ipl_stories_speaking {
    color: var(--text-blue);
  }

  .ipl_stories_notSpeaking {
    color: var(--text-white);
  }

  .ipl_trivizha_main_header h3 {
    /* padding: 35px; */
    padding: 8px;
    line-height: 41.48px;
  }

  .card_main-heading h2 {
    font-size: 30px;
    line-height: 35.55px;
    text-align: center;
  }

  .ipl_trivizha_main .card .card-body {
    padding: 30px 16px;
  }

  .ipl_trivizha_options ul li {
    width: 100%;
    max-width: 600px;
    margin: auto;
    line-height: 28.44px;
    padding: 12px;
    margin-top: 20px;
    font-size: 24px;
  }

  .ipl_trivizha_main .card .card-header p,
  .ipl_trivizha_main .card .card-footer p {
    font-size: 20px;
    line-height: 23.7px;
  }

  .ipl_trivizha_main .card .card-header img {
    width: 40px;
    height: 40px;
  }

  /* .ipl_trivizha_msg_input_container {
    display: none;
  } */
}

.ipl_btn_ask,
.ipl_btn_earn {
  border: none;
  border-radius: 30px;
  padding: 5px 10px;
}

.ipl_btn_ask {
  display: flex;
  align-items: center;
  gap: 7px;
}

.join_btn {
  border: none;
  border-radius: 50px;
  padding: 5px 20px;
}

.share_section{
  display: flex;
  align-items: center;
}

.share_section span{
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--font-weight-500);
}

/* ZOOM-IN */
.zoom_in {
  width: 40%;
}

.zoom_out {
  width: 20%;
}

@media (max-width: 1200px) {
  .stream_parent .chatbox_wrapper .chat_minimize_icon {
    right: 10%;
  }
}

@media (max-width: 768px) {
  .zoom_in {
    width: 60%;
  }

  .zoom_out {
    width: 40%;
  }

  .streaming_responsive {
    width: 90%;
    max-height: calc(100% - 140px);
  }

  .stream_parent .chatbox_wrapper .chat_minimize_icon {
    right: 0%;
  }

  /* .checking_ht{
    height: max-content;
  } */
}

/* ZOOM-IN */