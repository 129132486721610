.wallet_header {
  height: 70px;
}

.wallet_header h1 {
  font-size: 20px;
}

.pearbee_wallet_wrap {
  display: flex;
  gap: 48px;
  flex-direction: column;
  margin: 48px auto;
}

.pearbee_wallet_profile_sec {
  display: flex;
  gap: 12px;
  align-items: center;
}

.pearbee_wallet_profile_sec div {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.pearbee_wallet_profile_sec p {
  color: var(--text-glass);
  font-weight: var(--font-weight-300);
  font-family: var(--font-family-rubik);
  font-size: 14px;
  line-height: 16.59px;
}

.wallet_address_sec {
  display: flex;
  gap: 5px;
  flex-direction: row !important;
  align-items: center;
}

.pearbee_wallet_profile_sec p:nth-child(2) {
  font-weight: var(--font-weight-500);
  color: var(--text-white);
  font-size: 12px;
  line-height: 23.7px;
}

.pearbee_wallet_profile_list_wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pearbee_wallet_profile_list_wrap div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pearbee_wallet_profile_deposit {
  display: flex;
  flex-direction: column !important;
  gap: 10px;
}

.claim_btn_wrapper{
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: end;
}

.claim_btn_wrapper span{
  font-size: 12px;
  color: var(--text-gray-7);
  text-align: end;
}

.claim_btn_wrapper .btn{
  margin-left: auto;
  width: max-content;
}

.claim_btn_wrapper .btn:disabled{
  background-color: var(--bg-primary);
  color: var(--text-white);
}

.pearbee_wallet_profile_deposit + div a {
  color: var(--text-primary);
  font-size: 14px;
  font-family: var(--font-weight-400);
  line-height: 16.59px;
}

.pearbee_wallet_profile_deposit h5 {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pearbee_wallet_profile_deposit h5 span {
  font-weight: var(--font-weight-500);
  color: var(--text-white) !important;
  font-size: 12px;
  line-height: 23.7px;
}

.pearbee_wallet_profile_list_sec {
  padding: 10px 20px;
  border-radius: 11px;
  background-color: var(--bg-secondary);
}

.claim_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pearbee_wallet_profile_list_sec h5 {
  font-weight: var(--font-weight-400);
  font-family: var(--font-family-rubik);
  color: var(--text-gray-7);
  font-size: 14px;
  line-height: 16.59px;
}

.pearbee_wallet_profile_list_sec h4 {
  font-family: var(--font-family-rubik);
  font-weight: var(--font-weight-500);
  font-size: 18px;
}

.pearbee_wallet_link_sec a {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  border-bottom: 1px solid var(--text-gray-7);
  color: var(--text-gray-7);
  font-size: 18px;
  line-height: 21.6px;
  font-family: var(--font-family-lato);
  font-weight: var(--font-weight-400);
  align-items: center;
}
