.leaderboard_winning_breakup {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 4px 0px 0px 4px;
  background: linear-gradient(
        180deg,
        rgba(5, 101, 181, 0.3) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      padding-box,
    linear-gradient(180deg, #1070c6 0%, #014a7f 100%) border-box;
}

.pbee_leaderBoard h4,
.pbee_leaderBoard h6,
.pbee_leaderBoard span {
  font-family: var(--font-family-poppins);
  font-weight: var(--font-weight-500);
}
.leaderboard_winning_breakup h6 {
  line-height: 16px;
}

.pbee_leaderboard_weekly_yourRank,
.pbee_leaderBoard .nav .nav-item .nav-link.active {
  border: 0.5px solid var(--border-white);
}

.pbee_leaderboard_weekly_rank_block,
.pbee_leaderboard_weekly_list {
  border-bottom: 0.5px solid var(--border-white);
}

.pbee_leaderBoard {
  margin-top: 20px;
}
.pbee_leaderBoard .nav {
  gap: 12px;
}
.pbee_leaderBoard .nav .nav-item .nav-link {
  width: 120px;
  border-radius: 30px;
  padding: 5px;
  background-color: var(--bg-secondary);
  color: var(--text-white);
  font-size: 12px;
  font-family: var(--font-family-poppins);
}

.pbee_leaderBoard .nav .nav-item .nav-link.active {
  background: var(--bg-blue);
  color: var(--text-white);
  width: max-content;
}

.pbee_leaderBoard .nav .nav-item a {
  display: flex;
  gap: 5px;
  align-items: center;
}

.pbee_leaderBoard_img_wrap {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.pbee_leaderBoard_img_wrap_banner {
  width: 100%;
  /* max-width: 400px; */
  height: 130px;
  margin: 20px 0px 0px 0px;
  /* border: 1px solid var(--border-white); */
  border-radius: 20px;
}

.pbee_leaderBoard_img_wrap_banner img {
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 20px;
}

.pbee_leaderBoard_comming_soon_sec > span {
  color: var(--text-white);
  margin-top: 10px;
  display: block;
  font-style: italic;
}

.pbee_leaderBoard_information p {
  text-align: start;
  margin-top: 10px;
  font-size: 14px;
  margin-left: 10px;
  color: var(--text-white);
  gap: 5px;
  display: flex;
  align-items: center;
}

.pbee_leaderBoard_img_wrap img {
  width: 100%;
}

.pbee_leaderboard_weekly_heading_wrap {
  background: var(--bg-dark-blueish-black);
  padding: 10px 0px;
  border-bottom: 0.5px solid var(--border-white);
  margin: 20px 0px;
}

.pbee_leaderboard_weekly_heading {
  text-align: center;
  color: var(--text-white);
}

.pbee_leaderboard_weekly_heading span {
  font-size: 14px;
  line-height: 15px;
  color: var(--text-light-white-1);
}

.pbee_leaderboard_weekly_rank_block {
  padding: 16px 10px 28px;
}

.pbee_leaderboard_weekly_yourRank {
  position: relative;
  border-radius: 12px;
  min-height: 56px;
  overflow: hidden;
  padding: 37px 15px 15px;
}

.pbee_leaderboard_rank_top {
  font-family: var(--font-family-poppins);
  font-weight: var(--font-weight-600);
  position: absolute;
  top: 0;
  left: 0;
  background: var(--bg-warning-gradient);
  padding: 2px 11px;
  color: var(--text-black);
  line-height: 18px;
}

.pbee_leaderboard_coins h4 {
  color: var(--text-warning-3);
}
.pbee_leaderboard_rank h4 {
  color: var(--text-success-2);
}
.pbee_leaderboard_email_or_number h4 {
  font-size: 14px;
}
.pbee_leaderboard_weekly_list {
  padding: 20px 15px;
  align-items: center;
}

/* offcanvas */
.winning_breakup_offcanvas {
  /* height: 80vh; */
  min-height: 40vh !important;
  background-color: var(--bg-white);
}

.winning_breakup_frame_wrap {
  display: flex;
  justify-content: center;
  background: var(--bg-white);
}

.winning_breakup_frame_wrap html body {
  background-color: red;
}
