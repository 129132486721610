.pbee_event_overview_sec {
  padding: 20px 16px;
  margin-bottom: 60px;
}

.pbee_event_overview_header {
  gap: 15px;
  display: flex;
  flex-direction: column;
  color: var(--text-glass);
}

.pbee_event_overview_header h1 {
  font-weight: var(--font-weight-700);
  font-family: var(--font-family-sans);
  line-height: 21.79px;
}

.pbee_event_overview_header p {
  font-family: var(--font-weight-500);
  line-height: 18px;
}

.pbee_event_overview_header p span {
  color: var(--text-sandal);
  line-height: 18px;
  cursor: pointer;
}

/* EVENT-TIMELINE-CUSTOMIZE */
.pbee_event_overview_timeline {
  padding-top: 20px;
}

.timeline-box {
  position: relative;
  overflow: hidden;
}

.timeline-box:before {
  content: "";
  position: absolute;
  top: 30px;
  left: 19px;
  width: 2px;
  height: 67%;
  background: var(--text-sandal);
  z-index: 1;
}

.pbee_timeline_wrap {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.pbee_timeline_content h5 {
  font-weight: var(--font-weight-700);
}

.pbee_timeline_content p {
  color: var(--text-glass);
  font-size: 10px;
}

.marker {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid var(--text-sandal);
  background: var(--text-sandal);
  margin-top: 10px;
  z-index: 9999;
  text-align: center;
  position: relative;
  left: 11px;
  top: 10px;
}

.marker.active {
  width: 18px;
  height: 18px;
  background: var(--text-sandal);
  left: 11px;
  top: 17px;
}

.marker.active svg {
  position: absolute;
  left: 2px;
  top: 2px;
}

.pbee_timeline_content {
  width: 95%;
  padding: 30px 0 0 10px;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.pbee_timeline_wrap {
  width: 100%;
}

/* EVENT-TIMELINE-CUSTOMIZE */

.pbee_timeline_source_truth {
  margin-top: 28px;
}

.pbee_timeline_source_truth p {
  background-color: var(--bg-sandal);
  padding: 8px 11px;
  width: max-content;
  border-radius: 4px 4px 0px 0px;
  font-family: var(--font-family-poppins);
  font-size: 12px;
}

.pbee_timeline_source_truth_link_sec {
  padding: 8px;
  border-radius: 0px 4px 4px 4px;
  border: 0.5px solid var(--text-sandal);
  background: var(--bg-light-gray);
}

/* EVENT-OVERVIEW-OFFCANVAS */

.event_overview_offcanvas_wrapper {
  padding: 0px 15px 5px 15px;
  /* height: 100%;
  overflow-y: scroll;
  max-height: calc(100% - 60px); */
}

.event_overview_offcanvas.offcanvas-bottom{
  /* height: max-content;
  max-height: 80vh; */
  /* height: max-content; */
}

.offcanvas.offcanvas-bottom .offcanvas-header {
  flex-direction: column;
}

.event_overview_offcanvas .offcanvas-body{
  padding: 0px;
  height: 100%;
  /* min-height: 10px; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pbee_question_offcanvas .progress {
  border: 1px solid var(--border-light-gray-3);
  background-color: var(--progress-primary-light);
}

.pbee_question_progress_sec {
  position: relative;
}

.pbee_question_progress_sec span {
  position: absolute;
  right: 10px;
  top: 5px;
  color: var(--text-gray-8);
  font-family: var(--font-family-lato);
  font-size: 14px;
}

.pearbee_ans_selected {
  border: 1px solid var(--border-primary) !important;
}

.pearbee_ans_selected .progress-bar {
  background-color: var(--border-primary) !important;
  color: var(--text-white);
}

.pearbee_ans_selected+span.pearbee_calculate_percent{
  color: var(--text-white);
}

.pbee_question_progress_sec .progress-bar {
  background-color: var(--progress-primary-light);
}

.pbee_event_overview_offcanvas_header_desc {
  display: flex;
  align-items: start;
  gap: 5px;
  color: var(--text-light-gray-4);
}

.pbee_event_overview_offcanvas_header_wrap {
  display: flex;
  flex-direction: column;
  /* padding: 15px 10px 10px 10px; */
  padding: 5px 15px 5px 15px;
  gap: 11px;
  justify-content: start;
  width: 100%;
}

.pbee_event_overview_offcanvas_header_wrap p {
  font-size: 14px;
}

.pbee_event_overview_offcanvas_header_wrap p:nth-child(2) {
  font-size: 10px;
}

.pbee_event_overview_quantity {
  border-radius: 10px;
  min-height: 61px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  /* margin-top: 0px; */
}

.pbee_event_overview_qty_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pbee_event_overview_qty_header p {
  color: var(--text-gray-1);
  font-weight: var(--font-weight-600);
  line-height: 12px;
}

.pbee_event_overview_quantity-rhs {
  display: flex;
  align-items: center;
  gap: 5px;
}
.pbee_event_overview_quantity-rhs span {
  font-family: var(--font-family-lato);
  border-bottom: 1px solid var(--text-dark);
  font-weight: var(--font-weight-700);
  font-size: 14px;
  line-height: 12px;
}

.pbee_event_overview_quantity-rhs input[type="text"]{
  width: 30%;
  margin-left: auto;
  margin-top: 5px;
  border: 1px solid var(--bg-dark);
  border-radius: 5px;
}

.pbee_event_overview_investment_block {
  background-color: var(--bg-light-gray);
  /* min-height: 88px; */
  min-height: 40px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 8px 20px;
  /* margin-top: 14px; */
}

.investment_lhs,
.investment_rhs {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 9px;
  flex: 1;
}
.investment_rhs {
  border-left: 1px solid var(--border-light-gray-2);
}

.investment_rhs p span {
  display: block;
  font-size: 14px;
  color: var(--text-dark);
}

.pbee_event_overview_investment_block span {
  font-family: var(--font-family-sans);
  line-height: 16px;
  font-weight: var(--font-weight-700);
}
.investment_lhs span {
  color: var(--text-black);
}

.investment_rhs span {
  color: var(--text-success);
}

.pbee_event_overview_investment_block p {
  line-height: 15px;
  color: var(--text-light-gray-5);
  font-size: 10px;
}

.pbee_event_overview_offcanvas-btn {
  width: 100%;
  border-radius: 40px;
  padding: 12px;
  /* margin-top: 18px; */
  margin-top: 0px;
}

.event_overview_offcanvas_footer {
  background-color: var(--bg-light-gray-1);
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.event_overview_offcanvas_footer p {
  color: var(--text-dark);
  line-height: 16px;
  font-size: 10px;
  font-family: var(--font-family-lato);
}

/* OFFCANVAS SUCCESS */

/* RANGE */
.range-slider {
  -webkit-appearance: none;
  /* Override default CS styles */
  appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 30px;
  background: var(--bg-light-gray-1);
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  box-shadow: none;
}

.range-slider:hover {
  opacity: 1;
}

.range-slider:focus {
  background: var(--bg-dark);
}

.range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: var(--bg-dark);
  cursor: pointer;
  border-radius: 50%;
}

.range-slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: var(--bg-dark);
  cursor: pointer;
}

/* RANGE */

.event_success_offcanvas .offcanvas-header {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 34px;
}
.event_success_offcanvas .offcanvas-title {
  font-weight: var(--font-weight-700);
  font-size: 25px;
  font-family: var(--font-family-poppins);
  line-height: 37.5px;
}

.event_success_offcanvas .offcanvas-body {
  text-align: center;
  margin: 33px 0px 33px 0px;
}

.event_success_offcanvas .offcanvas-body p {
  font-family: var(--font-family-poppins);
  line-height: 18px;
  color: var(--text-gray-3);
  margin-top: 11px;
  font-size: 12px;
}

.event_success_offcanvas .offcanvas-body .btn {
  width: 100%;
  margin-top: 26px;
  border-radius: 40px;
}

.event_success_offcanvas.offcanvas-bottom {
  /* height: 35vh; */
  height: max-content !important;
  padding: 10px;
}

.confirm_popup {
  z-index: 99999;
}

.event_error_show_wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 100%;
  min-height: 180px;
  justify-content: space-around;
  width: 100%;
}

.event_error_show_wrap h2 {
  color: var(--text-dark);
  text-align: center;
  font-size: 18px;
}

.singl_ques_loading{
  min-height: 300px;
}

@media (max-width: 768px) {
  .pbee_timeline_content {
    padding: 25px 0 0 25px;
  }
}

/* earn-overwrite-card */
.earn_progress .pearbee_ans_selected .progress-bar{
  width: 100%;
}
