.invite_earn_header h1 {
  font-family: var(--font-family-poppins);
  font-size: 20px;
  line-height: 30px;
}

.invite_earn_container h1 {
  font-family: var(--font-family-poppins);
  font-weight: var(--font-weight-500);
  font-size: 14px;
  line-height: 21px;
  padding-top: 20px;
}

.inviteEarn_card {
  padding: 12px 18px;
  margin-top: 30px;
  width: 100%;
  max-width: 360px;
  margin: 26px auto 0px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: var(--bg-secondary);
}

.inviteEarn_card div:first-child {
  border-bottom: 1px solid var(--border-gray);
  padding-bottom: 13px;
}

.inviteEarn_card div:nth-child(2) {
  padding: 11px 0px 0px;
}

.inviteEarn_card div:first-child h4 {
  font-size: 12px;
  line-height: 16.34px;
}

.inviteEarn_card h2 {
  font-size: 16px;
  line-height: 21.79px;
  color: var(--text-success);
}

.inviteEarn_card_head-2 {
  color: var(--text-gray-10);
  font-size: 12px;
  line-height: 16.34px;
}

.inviteEarn_card .btn {
  padding: 0;
}

.invite_offcanvas {
  max-width: 600px;
  width: 100%;
  margin: auto;
  border-radius: 20px 20px 0px 0px;
  background-color: var(--bg-white);
  padding: 30px;
}

/* .invite_offcanvas.offcanvas-bottom{
  height: 30vh;
} */

.invite_offcanvas .offcanvas-header {
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 15px;
}

.invite_offcanvas .offcanvas-header img {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}

.invite_offcanvas .offcanvas-header h4 {
  line-height: 16.34px;
  color: var(--text-secondary-subtle);
  font-size: 12px;
}

.invite_offcanvas .offcanvas-header h2 {
  font-size: 12px;
  line-height: 16.34px;
  color: var(--text-dark);
}

.invite_offcanvas .offcanvas-header .btn {
  font-family: var(--font-family-sans);
  font-size: 10px;
  font-weight: 700;
  line-height: 13.62px;
  border-radius: 6px;
  padding: 8px 11px;
}

.invite_offcanvas .offcanvas-body h2 {
  font-size: 16px;
  line-height: 21.79px;
  color: var(--text-dark);
  margin-top: 24px;
}

.pbee_how_it_works {
  display: flex;
  flex-direction: column;
  margin-top: 29px;
  gap: 47px;
}

.invite_offcanvas .offcanvas-body span {
  border: 1px solid #000000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.invite_offcanvas .offcanvas-body p {
  font-family: var(--font-family-poppins);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

/*YOUR INVITES */

.yourInvite_header h1 {
  font-size: 20px;
  line-height: 27.24px;
}

.yourInvite_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
}

.invites_card {
  background-color: var(--bg-secondary);
  width: 100%;
  max-width: 311px;
  margin: auto;
  padding: 12px 24px;
  border-radius: 11px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invites_card h4 {
  color: var(--text-white);
  font-size: 12px;
  line-height: 16.34px;
}

.invites_card p {
  font-family: var(--font-family-sans);
  font-size: 8px;
  font-weight: 400;
  line-height: 10.89px;
  color: var(--text-gray-11);
}

.invites_card h2 {
  font-size: 12px;
  line-height: 16.34px;
  font-weight: var(--font-weight-600);
  color: var(--text-success);
}
