/* SETTINGS PAGE */

.settings_header h1 {
  font-size: 20px;
  line-height: 27.24px;
}

.add_email_class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 37px 16.5px;
  background-image: var(--repeat-linear-gradient--);
  background-size: 0px 0%, 0% 0px, 0px 0%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  /* width: 50%;
  margin: 0 auto; */
}

.add_email_class h3 {
  font-family: var(--font-family-poppins);
  font-size: 12px;
  color: var(--text-light-white);
  line-height: 18px;
}

.add_email_class h1 {
  font-size: 16px;
  line-height: 24px;
  color: var(--text-primary);
  letter-spacing: 1px;
}

.menu_container {
  width: 100%;
  max-width: 600px;
  margin: 25.5px auto 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  height: 100%;
  min-height: 560px;
}

.menu_container .settings-btn img {
  width: 24px;
  height: 24px;
}

.menu_container .settings-btn div {
  display: flex;
  align-items: center;
  gap: 12px;
}
.page_container .settings-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
  color: var(--text-gray-2);
  font-family: var(--font-family-lato);
  font-size: 18px;
  font-weight: var(--font-weight-400);
  line-height: 21.6px;
  border: none;
}

.settings_footer_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  width: 100%;
  margin: 40px 0px 0px 0px;
  justify-content: center;
}
.settings_footer_container h1 {
  color: var(--text-light-white-1);
  font-family: var(--font-family-poppins);
  font-weight: var(--font-weight-500);
  font-size: 12px;
  line-height: 18px;
}

/* .settings_footer_container h1 {
 
} */

.settings_footer_container h1:nth-child(2) {
  font-size: 14px;
  line-height: 21px;
}

/* RESPONSIVE */

@media (min-width: 768px) {
  .add_email_class h6 {
    font-size: 16px;
  }
  .add_email_class p {
    font-size: 18px;
  }
}

@media (min-width: 568px) {
  .add_email_class {
    width: 50%;
    margin: 0 auto;
  }
}
